import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Box from '@mui/material/Box';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.primary.main,
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 12,
        width: 12,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(0, 0, 0, 0.16)',
        },
        '&:focus, &:active': {
            boxShadow: '0 0 0 14px rgba(0, 0, 0, 0.16)',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: theme.palette.primary.main,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
}));

const VideoContext = React.createContext();

export default function VideoAdCard({ adData }) {
    const [expanded, setExpanded] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [isMuted, setIsMuted] = React.useState(false);
    const [volume, setVolume] = React.useState(50); // Volume state
    const [currentTime, setCurrentTime] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const videoRef = React.useRef(null);
    const { currentVideo, setCurrentVideo, currentVideoState, setCurrentVideoState } = React.useContext(VideoContext);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            if (currentVideo && currentVideo !== videoRef.current) {
                currentVideo.pause();
                currentVideoState.setIsPlaying(false); // Update the play state of the previous video
            }
            videoRef.current.play();
            setCurrentVideo(videoRef.current);
            setCurrentVideoState({ setIsPlaying });
            setIsPlaying(true);
        }
    };

    const handleMuteUnmute = () => {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
        videoRef.current.volume = newValue / 100;
        setIsMuted(newValue === 0);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
    };

    const handleSliderChange = (event, newValue) => {
        videoRef.current.currentTime = newValue;
        setCurrentTime(newValue);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <Card sx={{ maxWidth: { xs: '100%', sm: 350 }, margin: 'auto' }}>
            <CardHeader
                avatar={
                    adData.advertisers ? (
                        <Avatar src={adData.advertisers.avatar} aria-label="advertiser" sx={{ width: 40, height: 40 }} />
                    ) : (
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="author">
                            {adData.author[0]} {/* Display first letter of author */}
                        </Avatar>
                    )
                }
                title={
                    <Typography variant="h6" sx={{ textAlign: 'left' }}>
                        {adData.author} {/* Author aligned to the left */}
                    </Typography>
                }
                subheader={
                    <Typography variant="body2" sx={{ textAlign: 'left', marginTop: -1 }}>
                        {adData.category} {/* Subheader aligned close to the title */}
                    </Typography>
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary" component="p">
                    {adData.title} {/* Description of the ad */}
                </Typography>
            </CardContent>
            <CardMedia
                component="video"
                height="194"
                // controls
                poster={adData.thumbnail}  /* Thumbnail image for the video */
                src={adData.video}  /* URL of the video */
                alt={adData.title}
                preload="metadata"  /* Load only metadata initially */
                loading="lazy"  /* Lazy load the video */
                ref={videoRef}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
            />
            <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', paddingBottom:0 }}>
                <CustomSlider
                    value={currentTime}
                    onChange={handleSliderChange}
                    aria-labelledby="video-slider"
                    min={0}
                    max={duration}
                    valueLabelDisplay="auto"
                    valueLabelFormat={formatTime}
                    sx={{ flexGrow: 1 }}
                />
                <Box sx={{ minWidth: 40, textAlign: 'right' }}>
                    <Typography variant="body2" color="text.secondary">
                        {formatTime(duration - currentTime)} {/* Display remaining time */}
                    </Typography>
                </Box>
            </Box>
            <CardActions disableSpacing sx={{ display: 'flex', alignItems: 'center', paddingTop:0 }}>
                <IconButton onClick={handlePlayPause} aria-label="play/pause" size="small">
                    {isPlaying ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
                </IconButton>
                <IconButton onClick={handleMuteUnmute} aria-label="mute/unmute" size="small">
                    {isMuted ? <VolumeOffIcon fontSize="small" /> : <VolumeUpIcon fontSize="small" />}
                </IconButton>
                <CustomSlider
                    value={volume}
                    onChange={handleVolumeChange}
                    aria-labelledby="volume-slider"
                    min={0}
                    max={100}
                    sx={{ width: 80, marginLeft: 1 }}
                />
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold', textAlign: 'left' }}>
                        Video Information:
                    </Typography>
                    <Typography sx={{ textAlign: 'left' }}>
                        Number of views: {adData.number_of_views} {/* Number of views */}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export function VideoProvider({ children }) {
    const [currentVideo, setCurrentVideo] = React.useState(null);
    const [currentVideoState, setCurrentVideoState] = React.useState({ setIsPlaying: () => { } });

    return (
        <VideoContext.Provider value={{ currentVideo, setCurrentVideo, currentVideoState, setCurrentVideoState }}>
            {children}
        </VideoContext.Provider>
    );
}