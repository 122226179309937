// src/AdvertiserCard.js
import * as React from 'react';
import { Card, CardContent, Typography, Avatar, Box } from '@mui/material';

export default function AdvertiserCard({ advertiser }) {
  return (
    <Card sx={{ minWidth: 100, margin: '0 10px' }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar src={advertiser.avatar} alt={advertiser.title} sx={{ width: 56, height: 56, marginBottom: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {advertiser.title}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}