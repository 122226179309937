import React, { useState, useEffect, useRef } from "react";
import Typesense from "typesense";
import InfiniteScroll from "react-infinite-scroll-component";
import "./App.css";
import VideoAdCard from "./VideoAdCard";
import AdvertiserCard from "./AdvertiserCard";
import { VideoProvider } from "./VideoAdCard";

const client = new Typesense.Client({
  nodes: [
    {
      host: "engine-01.scouttly.com", // Change to your Typesense host
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: "kq7KSJvV3mhbnlG90N2lGymzPFnln04F", // Add your Typesense API Key
  connectionTimeoutSeconds: 10,
});

const videoLink = "https://www.youtube.com/watch?v=";
const channelLinks = [
  "https://www.youtube.com/channel/",
  "https://www.youtube.com/user/",
  "https://www.youtube.com/c/",
  "https://www.youtube.com/@",
]

function getQuery(query) {
  const isVideoLink = query.includes(videoLink);
  const isChannelLink = channelLinks.some((link) => query.includes(link));
  if (isVideoLink) {
    const videoId = query.split(videoLink)[1].split("?")[0];
    return videoId;
  } else if (isChannelLink) {
    const channelId = query.split(channelLinks.find((link) => query.includes(link)))[1];
    return channelId;
  }
  return query;
}

function App() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const inputRef = useRef(null);



  const [showPopularSearches, setShowPopularSearches] = useState(false);
  const [popularSearches, setPopularSearches] = useState([]);

  const handleFocus = () => {
    getPopularSearches().then((searches) => setPopularSearches(searches));
    setShowPopularSearches(false);
  };

  const handleBlur = () => {
    // Delay hiding to allow click events on the list items
    setTimeout(() => {
      setShowPopularSearches(false);
    }, 200);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearchClick = (search) => {
    setQuery(search);
    setShowPopularSearches(false);
  };

  const getPopularSearches = async () => {
    // Check if the query is a link to a video or channel and extract the video ID or channel ID



    try {
      const searchResults = await client.collections("analytics_ads_queries").documents().search({
        q: "",
        query_by: "*",
        sort_by: "count:desc",
        per_page: 10,
      });

      console.log("Popular searches:", searchResults.hits.map((hit) => hit.document.query));
      return searchResults.hits.map((hit) => hit.document.q);
    } catch (error) {
      console.error("Error fetching popular searches:", error);
    }
  };

  const fetchResults = async () => {
    try {
      let searchRequests = {
        searches: [
          {
            collection: "ads",
            q: getQuery(query),
            query_by: "author,title,description,category,transcription.text,video_id",
            // query_by_weights: "author:100,title:5,description:3,category:1,transcription.text:1,video_id:1",
            // sort_by: '_text_match(buckets: 1):desc',
            include_fields: "$advertisers(*)",
            // fuzzy_search: true,
            split_join_tokens: true,
            drop_tokens_threshold: 1,
            typo_tokens_threshold: 1,
            prefix: true,
            num_typos: 2,
            per_page: 20,
          },
          {
            collection: "advertisers",
            q: getQuery(query),
            query_by: "title",
            filter_by: "creation_date:>=0",
            per_page: 10,
          }
        ],
      };

      let commonSearchParams = {
        page,
      };

      const searchResults = await client.multiSearch.perform(searchRequests, commonSearchParams);

      const newResults = searchResults.results[0].hits.map((hit) => hit.document);
      const advertisers = searchResults.results[1].hits.map((hit) => hit.document);
      // console.log("Groups:", searchResults.results[2].hits);
      setResults((prev) => [...prev, ...newResults]);
      setHasMore(searchResults.results[0].found > 10);

      setAdvertisers(advertisers);
      setCount(searchResults.results[0].found);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    console.log("query:", query);
    const delayDebounceFn = setTimeout(() => {
      setResults([]);
      setPage(1); // Reset the page when query changes
      fetchResults();
    }, 300); // Add a debounce for better UX

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (page > 1) fetchResults();
  }, [page]);

  useEffect(() => {
    document.title = "Youtube Ads";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", "Search and view Youtube ads");
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = "Search and view Youtube ads";
      document.head.appendChild(meta);
    }

    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link.href = "/favicon.ico";
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = "/favicon.ico";
      document.head.appendChild(newLink);
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <VideoProvider>
      <div className="App">
        <h1 class="glitch" data-text="Scouttly">Scouttly</h1>
        <div className="search-wrapper">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search..."
            value={query}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="search-input"
          />
          <i className="fa fa-search"></i>

          {showPopularSearches && (
            <div className="popular-searches">
              <h3>Popular Searches</h3>
              <ul>
                {popularSearches.map((search, index) => (
                  <li key={index} onMouseDown={() => handleSearchClick(search)}>
                    {search}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <h2>{count}</h2>
        <div className="advertisers-list">
          {advertisers.map((advertiser, index) => (
            <AdvertiserCard key={index} advertiser={advertiser} />
          ))}
        </div>
        <InfiniteScroll
          dataLength={results.length}
          next={loadMore}
          hasMore={hasMore}
          loader={<h4>Loading more ads...</h4>}
          endMessage={<p>You have seen all the ads!</p>}
          className="results-grid"
        >
          {results.length > 0 && (
            <div className="grid-container">
              {results.map((result, index) => (
                <div key={index} className="grid-item">
                  <VideoAdCard adData={result} />
                </div>
              ))}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </VideoProvider>
  );
}

export default App;